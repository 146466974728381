<template>
  <div id="lookWpsbox"></div>
</template>

<script>
import Vue from "vue";
import { webofficeUrl, getWPSParam } from "@/api/classroom";
import { getToken } from "@/core/auth";
import { initBrowserPrompt } from "../../utils/index.js";
export default {
  name: "lookWps",
  data() {
    return {
      simpleMode: true, // 是否开启简易模式
      wpsSdkVersion: 2,
    };
  },
  mounted() {
    this.getWPSinfo();
    initBrowserPrompt();
  },
  methods: {
    /**
     * 获取WPS信息
     */
    getWPSinfo() {
      let _this = this;
      let id = _this.$route.query.id;
      let format = _this.$route.query.format;
      let version = _this.$route.query.version;
      let type = _this.$route.query.type;
      let courseId = _this.$route.query.courseId;
      let resourceType = _this.$route.query.resourceType;
      if (this.wpsSdkVersion === 1) {
        webofficeUrl({
          _w_file_id: id,
          _w_file_format: format.toLowerCase(),
          _w_permission: "read",
          _w_file_version: version,
          _w_file_code: courseId + "-" + resourceType,
          _w_file_source: "bk",
        }).then((res) => {
          _this.initwps(res.data.wpsUrl, res.data.token, type);
        });
      } else {
        let params = {
          _w_file_code: courseId + "-" + resourceType,
          _w_file_format: format.toLowerCase(),
          _w_file_id: id,
          _w_file_source: "bk",
          _w_file_version: version,
          _w_isUpdate: 0,
        };
        getWPSParam(params).then((res) => {
          _this.initwps2(res.data, getToken(), type);
        });
      }
    },

    /**
     * wps初始化
     */
    initwps(url, token, type) {
      let _this = this;
      // let url=_this.$route.query.url;
      // let token=_this.$route.query.token;
      // let type=_this.$route.query.type;//课件：1，教案：2
      const wps = _this.wps.config({
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector("#lookWpsbox"),
        wpsUrl: url,
        pptOptions: {
          isInSafeMode: true, // 安全模式
          isShowRemarkView: false, // 是否显示备注视图
        },
        commonOptions: {
          isShowTopArea: _this.isShowTopArea, // 隐藏顶部区域(头部和工具栏)
        },
        wordOptions: {
          isShowBottomStatusBar: false, // 是否展示底部状态栏
        },
        commandBars: [
          {
            cmbId: "WPPPcCommentButton", // 组件 演示-底部工具栏-评论按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "ContextMenuConvene", // 组件 word-右键-召唤在线协助者
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "BookMarkContextMenu", // 组件 文字-右键-插入书签
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "PlayComponentToolbar", // 组件 全屏播放时 Toolbar
            attributes: {
              visible: false, // 隐藏组件
            },
          },
        ],
        onToast: ({ msg: 当前浏览器不支持全屏播放, action: close }) => {},
      });
      wps.setToken({ token });

      _this.setppt(wps);
    },
    /**
     * wps初始化
     */
    initwps2(wpsParams, token, type) {
      let _this = this;
      // let url=_this.$route.query.url;
      // let token=_this.$route.query.token;
      // let type=_this.$route.query.type;//课件：1，教案：2
      const wps = _this.wps.init({
        officeType: wpsParams.w_file_format,
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector("#lookWpsbox"),
        appId: wpsParams.w_app_id,
        fileId: wpsParams.w_file_id,
        token,
        customArgs: wpsParams,
        pptOptions: {
          isInSafeMode: true, // 安全模式
          isShowRemarkView: false, // 是否显示备注视图
        },
        commonOptions: {
          isShowTopArea: _this.isShowTopArea, // 隐藏顶部区域(头部和工具栏)
        },
        wordOptions: {
          isShowBottomStatusBar: false, // 是否展示底部状态栏
        },
        commandBars: [
          {
            cmbId: "WPPPcCommentButton", // 组件 演示-底部工具栏-评论按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "ContextMenuConvene", // 组件 word-右键-召唤在线协助者
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "BookMarkContextMenu", // 组件 文字-右键-插入书签
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "PlayComponentToolbar", // 组件 全屏播放时 Toolbar
            attributes: {
              visible: false, // 隐藏组件
            },
          },
        ],
        onToast: ({ msg: 当前浏览器不支持全屏播放, action: close }) => {},
      });
      // wps.setToken({ token });

      _this.setppt(wps);
    },
    /**
     * ppt格式设置
     */
    async setppt(wps) {
      let _this = this;
      await wps.ready();
      const app = wps.Application;
      // Slide 设置对象
      // 监听进入幻灯片全屏播放

      // eslint-disable-next-line require-atomic-updates
      app.Sub.SlideShowBegin = async () => {
        const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
        // 视图对象
        const view = await SlideShowWindow.View;
        const menu = await app.Enum.PpToolType.pcPlayingMenu; // 右键菜单
        view.SetToolVisible(menu, false);
        view.ShowPage = true; // 【播放模式】显示页码
        const presentation = await app.ActivePresentation;
        // 幻灯片对象
        const slides = await presentation.Slides;
        // 获取总页数
        const count = await slides.Count;
        // 获取当前页码
        const curryPage = await app.ActivePresentation.SlideShowWindow.View
          .Slide.SlideIndex;
        if (curryPage !== 1 && count > 1) {
          // 跳转到指定页
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1);
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
#lookWpsbox {
  height: 100%;
  width: 100%;
}
</style>
